import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  ClearingTicket,
  ClearingTicketCustomService,
  ClearingTicketService,
  SeverityType,
  StatusType
} from '@adlatus-gui/domain/clearing';
import { ClearingTicketAssignmentService, ClearingTicketWatchService } from '@adlatus-gui/domain/clearing-custom';
import { convertToIsoString } from '@adlatus-gui/shared';


@Injectable({
  providedIn: 'root'
})
export class ClearingTicketUtils {
  public static readonly _xTotalCount = 'X-Total-Count';

  private _totalInbox = new BehaviorSubject<number>(0);
  private _totalOutbox = new BehaviorSubject<number>(0);
  private _totalMyIssues = new BehaviorSubject<string>('0');
  private _totalWatchingIssues = new BehaviorSubject<string>('0');


  get totalInbox$(): Observable<number> {
    return this._totalInbox.asObservable();
  }

  get totalOutbox$(): Observable<number> {
    return this._totalOutbox.asObservable();
  }

  get totalMyIssues$(): Observable<string> {
    return this._totalMyIssues.asObservable();
  }

  get totalWatchingIssues$(): Observable<string> {
    return this._totalWatchingIssues.asObservable();
  }

  updateInboxCount(count: number): void {
    this._totalInbox.next(count);
  }

  updateOutboxCount(count: number): void {
    this._totalOutbox.next(count);
  }

  updateMyIssuesCount(count: string): void {
    this._totalMyIssues.next(count);
  }

  updateWatchingIssues(count: string): void {
    this._totalWatchingIssues.next(count);
  }

  constructor(private clearingTicketService: ClearingTicketService,
              private clearingTicketAssignmentService: ClearingTicketAssignmentService,
              private clearingTicketCustomService: ClearingTicketCustomService,
              private watchClearingTicketService: ClearingTicketWatchService) {
  }

  listClearingTicketByProcessor(processor: string, ticketType: string | undefined, originator: string | undefined, severity: SeverityType | undefined, creationDateFrom: Date | undefined, creationDateTo: Date | undefined, status: StatusType | undefined, offset: number, limit: number): Observable<HttpResponse<Array<ClearingTicket>>> {
    return this.clearingTicketService.listClearingTicket(
      undefined,  // externalId
      ticketType,  // ticketType
      status,  // status
      originator,  // originator
      processor,  // processor
      severity,  // severity
      undefined,  // requestedResolutionDateFrom
      undefined,  // requestedResolutionDateTo
      creationDateFrom ? convertToIsoString(creationDateFrom) : undefined,  // creationDateFrom
      creationDateTo ? convertToIsoString(creationDateTo) : undefined,
      undefined,  // lastUpdateFrom
      undefined,  // lastUpdateTo
      offset,     // offset
      limit,      // limit
      'response',     // observe (optional, set to 'body' if needed)
      false       // reportProgress (optional, set to false if needed)
    );
  }

  listClearingTicketByOriginator(originator: string, ticketType: string | undefined, processor: string | undefined, severity: SeverityType | undefined, creationDateFrom: Date | undefined, creationDateTo: Date | undefined, status: StatusType | undefined, offset: number, limit: number): Observable<HttpResponse<Array<ClearingTicket>>> {
    return this.clearingTicketService.listClearingTicket(
      undefined,  // externalId
      ticketType,  // ticketType
      status,  // status
      originator,  // originator
      processor,  // processor
      severity,  // severity
      undefined,  // requestedResolutionDateFrom
      undefined,  // requestedResolutionDateTo
      creationDateFrom ? convertToIsoString(creationDateFrom) : undefined,  // creationDateFrom
      creationDateTo ? convertToIsoString(creationDateTo) : undefined,  // creationDateTo
      undefined,  // lastUpdateFrom
      undefined,  // lastUpdateTo
      offset,     // offset
      limit,      // limit
      'response',     // observe (optional, set to 'body' if needed)
      false       // reportProgress (optional, set to false if needed)
    );
  }

  inboxClearingTickets(processor: string): Observable<HttpResponse<Array<ClearingTicket>>> {
    return this.clearingTicketService.listClearingTicket(
      undefined,  // externalId
      undefined,  // ticketType
      undefined,  // status
      undefined,  // originator
      processor,  // processor
      undefined,  // severity
      undefined,  // requestedResolutionDateFrom
      undefined,  // requestedResolutionDateTo
      undefined,  // creationDateFrom
      undefined,  // creationDateTo
      undefined,  // lastUpdateFrom
      undefined,  // lastUpdateTo
      0,     // offset
      10000,      // limit
      'response',     // observe (optional, set to 'body' if needed)
      false       // reportProgress (optional, set to false if needed)
    );
  }

  outboxClearingTickets(originator: string): Observable<HttpResponse<Array<ClearingTicket>>> {
    return this.clearingTicketService.listClearingTicket(
      undefined,  // externalId
      undefined,  // ticketType
      undefined,  // status
      originator,  // originator
      undefined,  // processor
      undefined,  // severity
      undefined,  // requestedResolutionDateFrom
      undefined,  // requestedResolutionDateTo
      undefined,  // creationDateFrom
      undefined,  // creationDateTo
      undefined,  // lastUpdateFrom
      undefined,  // lastUpdateTo
      0,     // offset
      10000,      // limit
      'response',     // observe (optional, set to 'body' if needed)
      false       // reportProgress (optional, set to false if needed)
    );
  }

  myTickets(ticketType?: string, status?: StatusType, originator?: string, processor?: string, severity?: SeverityType, creationDateFrom?: Date, creationDateTo?: Date): Observable<Array<ClearingTicket>> {
    return this.clearingTicketAssignmentService.listMyIssues(
      ticketType,  // ticketType
      status,  // status
      originator,  // originator
      processor,  // processor
      severity,  // severity
      creationDateFrom ? convertToIsoString(creationDateFrom) : undefined,  // creationDateFrom
      creationDateTo ? convertToIsoString(creationDateTo) : undefined,  // creationDateTo
      0,          //page
      1000,       // lastUpdateFrom
      undefined
    );
  }

  myWatchingTickets(ticketType?: string, status?: StatusType, originator?: string, processor?: string, severity?: SeverityType, creationDateFrom?: Date, creationDateTo?: Date): Observable<Array<ClearingTicket>> {
    return this.watchClearingTicketService.listMyWatchingTickets(
      ticketType,  // ticketType
      status,  // status
      originator,  // originator
      processor,  // processor
      severity,  // severity
      creationDateFrom ? convertToIsoString(creationDateFrom) : undefined,  // creationDateFrom
      creationDateTo ? convertToIsoString(creationDateTo) : undefined,  // creationDateTo
      0,          //page
      1000,       // lastUpdateFrom
      undefined
    );
  }

  totalClearingTicketsCount(): Observable<HttpResponse<number>> {
    return this.clearingTicketCustomService.countClearingTicket(
      undefined,  // externalId
      undefined,  // ticketType
      undefined,  // status
      undefined,  // originator
      undefined,  // processor
      undefined,  // severity
      undefined,  // requestedResolutionDateFrom
      undefined,  // requestedResolutionDateTo
      undefined,  // creationDateFrom
      undefined,  // creationDateTo
      undefined,  // lastUpdateFrom
      undefined,  // lastUpdateTo
      'response',     // observe (optional, set to 'body' if needed)
      false       // reportProgress (optional, set to false if needed)
    );
  }

  totalClearingTicketsCountThisWeek(): Observable<HttpResponse<number>> {
    return this.clearingTicketCustomService.countClearingTicket(
      undefined,  // externalId
      undefined,  // ticketType
      undefined,  // status
      undefined,  // originator
      undefined,  // processor
      undefined,  // severity
      undefined,  // requestedResolutionDateFrom
      undefined,  // requestedResolutionDateTo
      this.getLastSunday(),  // creationDateFrom
      undefined,  // creationDateTo
      undefined,  // lastUpdateFrom
      undefined,  // lastUpdateTo
      'response',     // observe (optional, set to 'body' if needed)
      false       // reportProgress (optional, set to false if needed)
    );
  }

  inboxClearingTicketsCount(processor: string): Observable<HttpResponse<number>> {
    return this.clearingTicketCustomService.countClearingTicket(
      undefined,  // externalId
      undefined,  // ticketType
      undefined,  // status
      undefined,  // originator
      processor,  // processor
      undefined,  // severity
      undefined,  // requestedResolutionDateFrom
      undefined,  // requestedResolutionDateTo
      undefined,  // creationDateFrom
      undefined,  // creationDateTo
      undefined,  // lastUpdateFrom
      undefined,  // lastUpdateTo
      'response',     // observe (optional, set to 'body' if needed)
      false       // reportProgress (optional, set to false if needed)
    );
  }

  inboxThisWeekClearingTicketsCount(processor: string): Observable<HttpResponse<number>> {
    return this.clearingTicketCustomService.countClearingTicket(
      undefined,  // externalId
      undefined,  // ticketType
      undefined,  // status
      undefined,  // originator
      processor,  // processor
      undefined,  // severity
      undefined,  // requestedResolutionDateFrom
      undefined,  // requestedResolutionDateTo
      this.getLastSunday(),  // creationDateFrom
      undefined,  // creationDateTo
      undefined,  // lastUpdateFrom
      undefined,  // lastUpdateTo
      'response',     // observe (optional, set to 'body' if needed)
      false       // reportProgress (optional, set to false if needed)
    );
  }

  outboxClearingTicketsCount(originator: string): Observable<HttpResponse<number>> {
    return this.clearingTicketCustomService.countClearingTicket(
      undefined,  // externalId
      undefined,  // ticketType
      undefined,  // status
      originator,  // originator
      undefined,  // processor
      undefined,  // severity
      undefined,  // requestedResolutionDateFrom
      undefined,  // requestedResolutionDateTo
      undefined,  // creationDateFrom
      undefined,  // creationDateTo
      undefined,  // lastUpdateFrom
      undefined,  // lastUpdateTo
      'response',     // observe (optional, set to 'body' if needed)
      false       // reportProgress (optional, set to false if needed)
    );
  }

  outboxThisWeekClearingTicketsCount(originator: string): Observable<HttpResponse<number>> {
    return this.clearingTicketCustomService.countClearingTicket(
      undefined,  // externalId
      undefined,  // ticketType
      undefined,  // status
      originator,  // originator
      undefined,  // processor
      undefined,  // severity
      undefined,  // requestedResolutionDateFrom
      undefined,  // requestedResolutionDateTo
      this.getLastSunday(),  // creationDateFrom
      undefined,  // creationDateTo
      undefined,  // lastUpdateFrom
      undefined,  // lastUpdateTo
      'response',     // observe (optional, set to 'body' if needed)
      false       // reportProgress (optional, set to false if needed)
    );
  }

  clearingTicketsCountByStatus(status: string): Observable<HttpResponse<number>> {
    return this.clearingTicketCustomService.countClearingTicket(
      undefined,  // externalId
      undefined,  // ticketType
      status,  // status
      undefined,  // originator
      undefined,  // processor
      undefined,  // severity
      undefined,  // requestedResolutionDateFrom
      undefined,  // requestedResolutionDateTo
      undefined,  // creationDateFrom
      undefined,  // creationDateTo
      undefined,  // lastUpdateFrom
      undefined,  // lastUpdateTo
      'response',     // observe (optional, set to 'body' if needed)
      false       // reportProgress (optional, set to false if needed)
    );
  }

  clearingTicketsCountByStatusThisWeek(status: string): Observable<HttpResponse<number>> {
    return this.clearingTicketCustomService.countClearingTicket(
      undefined,  // externalId
      undefined,  // ticketType
      status,  // status
      undefined,  // originator
      undefined,  // processor
      undefined,  // severity
      undefined,  // requestedResolutionDateFrom
      undefined,  // requestedResolutionDateTo
      undefined,  // creationDateFrom
      undefined,  // creationDateTo
      this.getLastSunday(),  // lastUpdateFrom
      undefined,  // lastUpdateTo
      'response',     // observe (optional, set to 'body' if needed)
      false       // reportProgress (optional, set to false if needed)
    );
  }

  getLastSunday(): string {
    const today = new Date();
    const lastSunday = new Date(today.setDate(today.getDate() - today.getDay()));
    return lastSunday.toISOString().split('T')[0]; // Format as YYYY-MM-DD
  }

  // myTicketsCount(ticketType?: string, status?: StatusType, originator?: string, processor?: string, severity?: SeverityType, creationDateFrom?: Date, creationDateTo?: Date): Observable<Array<ClearingTicket>> {
  //   return this.clearingTicketCustomService.countClearingTicket(
  //     ticketType,  // ticketType
  //     status,  // status
  //     originator,  // originator
  //     processor,  // processor
  //     severity,  // severity
  //     creationDateFrom ? convertToIsoString(creationDateFrom) : undefined,  // creationDateFrom
  //     creationDateTo ? convertToIsoString(creationDateTo) : undefined,  // creationDateTo
  //     0,          //page
  //     1000,       // lastUpdateFrom
  //     undefined
  //   );
  // }

  // myWatchingTickets(ticketType?: string, status?: StatusType, originator?: string, processor?: string, severity?: SeverityType, creationDateFrom?: Date, creationDateTo?: Date): Observable<Array<ClearingTicket>> {
  //   return this.watchClearingTicketService.listMyWatchingTickets(
  //     ticketType,  // ticketType
  //     status,  // status
  //     originator,  // originator
  //     processor,  // processor
  //     severity,  // severity
  //     creationDateFrom ? convertToIsoString(creationDateFrom) : undefined,  // creationDateFrom
  //     creationDateTo ? convertToIsoString(creationDateTo) : undefined,  // creationDateTo
  //     0,          //page
  //     1000,       // lastUpdateFrom
  //     undefined
  //   );
  // }
}
